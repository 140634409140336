@import '_colors';
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap');

* {
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: $main-bg;
}

// TODO: Temporary hack for gallery arrows
.fa-arrow-circle-left {
  color: black !important;
}

.fa-arrow-circle-right {
  color: black !important;
}